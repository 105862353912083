
<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-6 col-12">Main Account Statement</div>
      <div class="
          col-lg-6 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
        <button type="button" @click="AccountStatementFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
          title="Filter" :disabled="dissableFilterButton == true">
          <span class="custom-filter-apply-alert" v-if="filterflag"></span>
          <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
      <div v-if="!showdataloader">
        <div class="comman-overflow-scroll-outer mb-3" style="height: calc(100vh - 157px)">
          <div class="accordion" id="accordionCompanyBilling">
            <div v-if="MonthData">
              <div class="mb-2" v-for="(item, index) in MonthData" :key="item.id">
              <!-- January start -->
                <div class="accordion-item" v-if="monthList.includes(index)">
                  <h2 class="accordion-header" :id="`#heading${index}`">
                    <button class="accordion-button collapsed custom-accordion-focus-out-btn" type="button"
                      data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="false"
                      :aria-controls="`collapse${index}`">
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <span>{{ index }} {{ MonthData[index+'_year'] }}</span>
                        <span class="me-3 d-flex">
                          <div class="service-plan-box-outer-credit me-3" style="width: 182px;">
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="call-label">Credit : </div>
                              <div class="call-count ps-1">
                                {{ vueNumberFormat(MonthData[index+'_credit'], {
                                  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                  acceptNegative: true
                                })
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="service-plan-box-outer-debit" style="width: 182px;" >
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="call-label">Debit : </div>
                              <div class="call-count ps-1">
                                {{ vueNumberFormat(MonthData[index+'_debit'], {
                                  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                  acceptNegative: true
                                })
                                }}
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </button>
                  </h2>
                  <div :id="`collapse${index}`" class="accordion-collapse collapse" :aria-labelledby="`#heading${index}`"
                    data-bs-parent="#accordionCompanyBilling">
                    <div class="accordion-body">
                      <div class="custom-ultima-datatable main-account-scroll-datatable">
                        <!-- :totalRecords="`${MonthData[index+'_count']}`" -->
                        <!-- paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" -->
                        <!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" -->
                        <DataTable :value="item" :scrollable="true" scrollHeight="flex" :paginator="false" :rows="30"
                          :lazy="true" :rowHover="true"  @page="onPage($event)" responsiveLayout="scroll" 
                          :loading="loading">
                          <template v-if="!loading" #empty>No records found.</template>
                          <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                          <Column field="date" header="Date & Time" headerStyle="width: 12%" bodyStyle="width: 12%">
                            <template #body="{ data }">
                              <div class="label-subheading" v-if="data.transaction_type == 2">{{ format_timestamp(data.credit_datetime) }}</div>
                              <div class="label-subheading" v-if="data.transaction_type == 1">{{ format_timestamp(data.debit_datetime) }}</div>
                            </template>
                          </Column>
                          <Column field="particulars" header="Particulars" headerStyle="width: 43%" bodyStyle="width: 43%">
                            <template #body="{ data }">
                              <div>
                                <div class="label-subheading">{{ data.perticulars }}</div>
                                <div class="mt-1 text-muted" v-if="data.debit_for == 1">({{ data.debit_component_count ? data.debit_component_count : "0" }} * {{
                                  vueNumberFormat(data.debit_component_charge, {
                                    prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                    acceptNegative: true
                                  })
                                }}) = {{ vueNumberFormat(data.debit_amount, {
        prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
        acceptNegative: true
      })
      }}</div>
                              </div>
                            </template>
                          </Column>
                          <Column field="credit" header="Credits" headerStyle="width: 15%" bodyStyle="width: 15%"
                            class="justify-content-end">
                            <template #body="{ data }">
                              <div class="label-subheading text-success">
                                {{ vueNumberFormat(data.credit_amount, {
                                  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                  acceptNegative: true
                                })
                                }}
                              </div>
                            </template>
                          </Column>
                          <Column field="debit" header="Debits" headerStyle="width: 15%" bodyStyle="width: 15%"
                            class="justify-content-end">
                            <template #body="{ data }">
                              <div class="label-subheading text-danger">
                                {{ vueNumberFormat(data.debit_amount, {
                                  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                  acceptNegative: true
                                })
                                }}
                              </div>
                            </template>
                          </Column>
                          <Column field="balance" header="Balance" headerStyle="width: 15%" bodyStyle="width: 15%"
                            class="justify-content-end">
                            <template #body="{ data }">
                              <div class="label-subheading">
                                {{ vueNumberFormat(data.balance_amount, {
                                  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                                  acceptNegative: true
                                })
                                }}
                              </div>
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="company-box-outer" v-else>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="pb-3">No records found.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showdataloader" style="height: 430px">
        <div class="custom-modal-spinner-loader">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </div>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="accountstatementmodalstatus">
      <div class="
      modal-dialog modal-xs modal-dialog-scrollable modal-dialog-centered
            custom-modal-outer
        ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Filter By
            </h5>
            <button type="button" class="btn-close" @click="AccountStatementFilterModalClose()"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label">From Date</label>
                  <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                    :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label">To Date</label>
                  <Calendar v-model="todate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                    dateFormat="dd/mm/yy" :minDate="fromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                    :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label for="accountentries" class="form-label">Show Entries</label>
                  <div class="custom-group-radio-box-btn">
                    <input type="radio" class="btn-check" v-model="accountentries" name="accountentries" id="credit"
                      autocomplete="off" v-bind:value="2" />
                    <label class="btn custom-radio-box-btn text-capitalize" for="credit">Credit</label>
                    <input type="radio" class="btn-check" v-model="accountentries" name="accountentries" id="debit"
                      autocomplete="off" v-bind:value="1" />
                    <label class="btn custom-radio-box-btn text-capitalize" for="debit">Debit</label>
  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 m-0">
              <div class="d-flex justify-content-between">
                <button :disabled="filterButtonShow == false" class="btn filter-apply-btn"
                  @click="applyFilteStatement(fromdate, todate, accountentries)">
                  Apply Filter
                </button>
                <button class="btn btn-link filter-reset-btn" @click="resetFilterMonth">
                  Reset All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- filter end here -->
  </template>
  <script>
  import ApiService from "../../service/ApiService";
  import moment from "moment";
  export default {
    data() {
      return {
        loading: false,
        accountstatementmodalstatus: false,
        fromdate: null,
        todate: null,
        accountentries: null,
        filterflag: false,
        dissableFilterButton: false,
        filterButtonShow: false,
        MonthData: [],
        showdataloader: false,
        monthList : new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"),
        totalRecords: 0,
        todaysDate: new Date(),
      }
    },
  
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
  
    mounted() {
      this.loading = true;
      this.getaccountStatement();
    },
    watch: {
      fromdate() {
        this.filterChanges();
      },
      todate() {
        this.filterChanges();
      },
      accountentries() {
        this.filterChanges();
      },
    },
    methods: {
      format_timestamp(value) {
        if (value) {
          var localTime = new Date(value * 1000).toUTCString();
          return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
        } else {
          return '-';
        }
      },
      getaccountStatement(e) {
        this.loading = true;
        this.showdataloader = true;
        this.ApiService.getaccountStatement(e).then((data) => {
          if (data.success == true) {
            this.MonthData = data.data;
            this.loading = false;
            this.showdataloader = false;
          } else {
            this.loading = false;
            this.MonthData = null;
            this.showdataloader = false;
          }
        });
      },
      onPage(event) {
        this.loading = true;
        this.page_no = event.page;
        this.getaccountStatement({
          page_no: this.page_no,
          fromdate: this.fromdate,
          todate: this.todate,
          accountentries_status: this.accountentries,
        });
      },
      filterChanges() {
        if (
          this.fromdate != null ||
          this.todate != null ||
          this.accountentries != null
        ) {
          this.filterButtonShow = true;
        } else {
          this.filterButtonShow = false;
        }
      },
      applyFilteStatement(fromdate, todate, accountentries) {
        this.loading = true;
        this.filterflag = true;
        this.accountentries = accountentries;
        this.accountstatementmodalstatus = false;
        if (fromdate != null && fromdate != null) {
          this.fromdate = moment(fromdate).format("YYYY-MM-DD");
        }
        if (todate != null && todate != null) {
          this.todate = moment(todate).format("YYYY-MM-DD");
        }
        this.getaccountStatement({
          type: 1,
          page_no: this.page_no,
          fromdate: this.fromdate,
          todate: this.todate,
          accountentries_status: this.accountentries,
        });
      },
      resetFilterMonth() {
        this.fromdate = null;
        this.todate = null;
        this.accountentries = null;
        this.getaccountStatement();
        this.filterflag = false;
      },
  
      AccountStatementFilterModalOpen() {
        this.accountstatementmodalstatus = true;
        if(this.fromdate != null) {
            this.fromdate = new Date(this.fromdate);
        }
        if(this.todate != null) {
            this.todate = new Date(this.todate);
        }
      },
  
      AccountStatementFilterModalClose() {
        this.accountstatementmodalstatus = false;
      },
    },
  };
  </script>
  <style scoped>.main-account-scroll-datatable {
    height: calc(100vh - 310px);
  }
  
  .company-detail-box-outer {
    position: relative;
    overflow: auto;
  }
  .company-box-outer {
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    margin-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }
  .service-plan-box-outer-credit {
      background: #e4f8f0 0% 0% no-repeat padding-box;
      border: 1px solid #e0e5ed;
      border-radius: 4px;
      padding: 6px 10px;
  }
  
  .service-plan-box-outer-credit .call-label {
      font-family: "AcuminPro-SemiBold";
      font-size: 14px;
      letter-spacing: 0.28px;
      color: #2b2e38;
      line-height: 20px;
      user-select: none;
  }
  
  .service-plan-box-outer-credit .call-count {
      font-family: "AcuminPro-SemiBold";
      font-size: 14px;
      letter-spacing: 0.32px;
      color: #4a5463;
      text-align: left;
      line-height: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  .service-plan-box-outer-debit {
    background: #f5e5e7 0% 0% no-repeat padding-box;
      border: 1px solid #e9cbcb;
      border-radius: 4px;
      padding: 6px 10px;
  }
  .service-plan-box-outer-debit .call-label {
      font-family: "AcuminPro-SemiBold";
      font-size: 14px;
      letter-spacing: 0.28px;
      color: #2b2e38;
      line-height: 20px;
      user-select: none;
  }
  
  .service-plan-box-outer-debit .call-count {
      font-family: "AcuminPro-SemiBold";
      font-size: 14px;
      letter-spacing: 0.32px;
      color: #4a5463;
      text-align: left;
      line-height: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  </style>